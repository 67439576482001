import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, html, #root {
    height: 100%;
    margin: 0;
    font-family: 'Amatic SC', cursive;
  }

  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1;
    padding-top: 70px;
  }

  footer {
    background-color: #000;
    color: white;
    text-align: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  /* Estilização da barra de rolagem */
  ::-webkit-scrollbar {
    margin-top:70px;
    width: 6px;
    height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 9px;
    box-shadow: 0 2px 4px rgba(250, 250, 250, 0.2); /* Adiciona uma sombra para o efeito flutuante */
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Aumenta a sombra ao passar o mouse para enfatizar o efeito */
}

::-webkit-scrollbar-track-piece {
}`

;

export default GlobalStyle;
