import React from 'react';
import styled from 'styled-components';
import backgroundImg from '../assets/img/agreg-background.jpg';

const Banner = styled.section`
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 85vh; /* Ajuste para cobrir toda a tela */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centraliza verticalmente */
  box-sizing: border-box;
  padding-bottom: 10vh;

  @media (max-width: 768px) {
    background-attachment: scroll;
    height: 90vh;
  }
`;

const BannerTitle = styled.h2`
  font-size: 5rem;
  color: white;
  font-family: 'Amatic SC', cursive;
  font-weight: bold;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 4rem;
  }

  @media (max-width: 480px) {
    font-size: 3.5rem;
  }
`;

const BannerText = styled.p`
  font-size: 2rem;
  width: 80vw;
  text-align: center;
  color: white;
  font-family: 'Amatic SC', cursive;
  padding: 2vh 0;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    width: 90vw;
  }

  @media (max-width: 480px) {
    font-size: 1.6rem;
    width: 95vw;
  }
`;

const Home = () => {
  return (
    <Banner>
      <BannerTitle>O BARDO</BannerTitle>
      <BannerText>
        Somos uma produtora e agregadora cultural independente, uma produtora
        preocupada com a realidade dos músicos, e artistas, brasileiros e
        engajada no fomento da cultura. <br />
        Oferecemos informação útil, entretenimento e serviços aos agentes da
        indústria cultural independente de tamanho e nicho.
      </BannerText>
    </Banner>
  );
};

export default Home;
