import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Home from './components/Home';
import QuemSomos from './components/QuemSomos';
import Produtos from './components/Produtos';
import Artistas from './components/Artistas';
import Portfolio from './components/Portfolio';
import GlobalStyle from './GlobalStyle';
import HeaderDesktop from './components/HeaderDesktop';
import HeaderMobile from './components/HeaderMobile';
import useMediaQuery from './hooks/useMediaQuery';
import './App.css';

function App() {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Router>
      <GlobalStyle />
      <div className="App">
        {isMobile ? <HeaderMobile /> : <HeaderDesktop />}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/quemsomos" element={<QuemSomos />} />
            <Route path="/produtos" element={<Produtos />} />
            <Route path="/artistas" element={<Artistas />} />
            <Route path="/portfolio" element={<Portfolio />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
