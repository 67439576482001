import React from 'react';
import styled from 'styled-components';
import background from '../assets/img/background.png';
import photo1 from '../assets/img/portifolio/1.jpg';
import photo2 from '../assets/img/portifolio/2.jpg';
import photo3 from '../assets/img/portifolio/3.jpeg';
import photo4 from '../assets/img/portifolio/4.jpg';
import photo5 from '../assets/img/portifolio/5.jpg';
import photo6 from '../assets/img/portifolio/6.jpg';
import photo7 from '../assets/img/portifolio/7.jpg';
import photo8 from '../assets/img/portifolio/8.jpg';
import photo9 from '../assets/img/portifolio/9.jpg';
import photo10 from '../assets/img/portifolio/10.jpg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const PageContainer = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding-bottom: 20px;
`;

const Container = styled.div`
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Amatic SC', cursive;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  color: white;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
`;

const CarouselImage = styled.img`
  max-height: 35vh;
  height: auto;
  object-fit: contain;
`;

const Legend = styled.p`
  font-size: 1.2rem;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  bottom: 0;
  width: 100%;
  text-align: center;
`;

const Portfolio = () => {
  return (
    <PageContainer>
      <Container>
        <SectionTitle>Portfólio</SectionTitle>
        <Carousel showThumbs={false} autoPlay infiniteLoop>
          <div>
            <CarouselImage src={photo1} alt="Fotografia 1" />
            <Legend>Fotografia 1</Legend>
          </div>
          <div>
            <CarouselImage src={photo2} alt="Fotografia 2" />
            <Legend>Fotografia 2</Legend>
          </div>
          <div>
            <CarouselImage src={photo3} alt="Fotografia 3" />
            <Legend>Fotografia 3</Legend>
          </div>
          <div>
            <CarouselImage src={photo4} alt="Fotografia 4" />
            <Legend>Fotografia 4</Legend>
          </div>
          <div>
            <CarouselImage src={photo5} alt="Fotografia 5" />
            <Legend>Fotografia 5</Legend>
          </div>
          <div>
            <CarouselImage src={photo6} alt="Fotografia 6" />
            <Legend>Fotografia 6</Legend>
          </div>
          <div>
            <CarouselImage src={photo7} alt="Fotografia 7" />
            <Legend>Fotografia 7</Legend>
          </div>
          <div>
            <CarouselImage src={photo8} alt="Fotografia 8" />
            <Legend>Fotografia 8</Legend>
          </div>
          <div>
            <CarouselImage src={photo9} alt="Fotografia 9" />
            <Legend>Fotografia 9</Legend>
          </div>
          <div>
            <CarouselImage src={photo10} alt="Fotografia 10" />
            <Legend>Fotografia 10</Legend>
          </div>
        </Carousel>
      </Container>
    </PageContainer>
  );
};

export default Portfolio;
