import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import bannerImg from '../assets/img/agreg-background.jpg';
import background from '../assets/img/background.png';

const PageContainer = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding-bottom: 20px; /* Para garantir que o fundo cubra toda a página */
`;

const Banner = styled.section`
  background-image: url(${bannerImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 20vh; /* Ajuste a altura conforme necessário */
`;

const BannerTitle = styled.h2`
  font-size: 5rem;
  color: white;
  font-family: 'Amatic SC', cursive;
  font-weight: bold;
  text-align: center;
`;

const Container = styled.div`
  padding: 20px;
  padding-bottom: 50px;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Amatic SC', cursive;
  background-color: rgba(0, 0, 0, 0.7); /* Fundo semi-transparente para o conteúdo */
  border-radius: 10px;
  color: white;
  margin-top: 5vh;
  margin-bottom: 5vh;
`;

const Text = styled.p`
  font-size: 1.8rem;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const StyledTabs = styled(Tabs)`
  .react-tabs__tab-list {
    border-bottom: 5px solid #fff;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    list-style-type: none;
  }

  .react-tabs__tab {
    padding: 8px 20px;
    cursor: pointer;
    font-weight: bold;
    color: #FFF;
    border: none;
    border-bottom: 2px solid transparent;
    transition: color 0.2s, border-bottom-color 0.2s;
    font-family: 'Amatic SC', cursive;
    font-weight: bold;
    text-align: center;
  }

  .react-tabs__tab--selected {
    color: #F50;
    border-bottom: 2px solid #f50;
  }

  .react-tabs__tab--disabled {
    color: #aaa;
    cursor: not-allowed;
  }

  .react-tabs__tab:focus {
    outline: none;
  }
`;

const QuemSomos = () => {
  return (
    <PageContainer>    
      <Banner>
        <BannerTitle>Quem somos</BannerTitle>
      </Banner>
      <Container>
        <Text>
          Somos curitibanos trabalhadores da cultura, independentes, e buscamos alternativas mais justas
          para a execução do fazer cultural. <br />
          Músicos há duas décadas, somos capazes de compreender a realidade do cenário cultural nacional
          e propor alternativas para a viabilização do trabalho na área cultura.
        </Text>
        <h1>
          <Link to="/portfolio" style={{ color: '#ff6347' }}>PORTFOLIO</Link>
        </h1>

        <StyledTabs>
          <h1>Ficha Técnica</h1>
          <TabList>
            <Tab>Carlos Félix</Tab>
            <Tab>Wilney Dovhepoly</Tab>
            <Tab>Lucas Corteletti</Tab>
            <Tab>Fernando Félix</Tab>
          </TabList>

          <TabPanel>
            <Text>
              Músico, produtor, empresário e desenvolvedor. Fotógrafo de formação, sempre esteve envolvido no meio artístico e audiovisual. <br />
              Hoje trabalha na busca pela dignidade e qualidade de vida de outros agentes culturais por meio do fomento da cultura.
            </Text>
          </TabPanel>

          <TabPanel>
            <Text>
              Músico, professor e produtor. Formando em produção musical pela Universidade Federal do Paraná, e professor de língua inglesa, <br />
              é o responsável pelas instruções de inglês para músicos, além de trabalhar ativamente na parte de produção musical e audiovisual dos nossos artistas parceiros.
            </Text>
          </TabPanel>

          <TabPanel>
            <Text>
              Músico e produtor cultural. Lucas Corteletti é diretor de execução dentro dos projetos executados pelo Bardo. <br />
              Tem ampla experiência com a gestão da execução de projetos e festivais.
            </Text>
          </TabPanel>

          <TabPanel>
            <Text>
              Músico, adestrador comportamental e produtor cultural. Fernando Félix é diretor de produção dentro dos projetos executados pelo Bardo. <br />
              Tem experiência com escrita e produção de projetos artístico culturais.
            </Text>
          </TabPanel>
        </StyledTabs>
      </Container>
    </PageContainer>
  );
};

export default QuemSomos;
