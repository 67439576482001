import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem; /* Reduzido o padding */
  background-color: #000;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  font-family: 'Amatic SC', cursive;
`;

const FooterText = styled.p`
  font-size: 0.8rem; /* Ajustado o tamanho da fonte */
  text-decoration: none;
  margin: 0; /* Removida a margem para compactar */
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: orange; 
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>&copy; Copyright - <Link href='/'>O Bardo DEV - 2024</Link></FooterText>
    </FooterContainer>
  );
};

export default Footer;
