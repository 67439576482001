import React from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import background from '../assets/img/background.png';
import bannerImg from '../assets/img/agreg-background.jpg';

const PageContainer = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding-bottom: 20px; /* Para garantir que o fundo cubra toda a página */
`;

const Banner = styled.section`
  background-image: url(${bannerImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 5vh;
  height: 20vh; /* Ajuste a altura conforme necessário */
`;

const BannerTitle = styled.h2`
  font-size: 5rem;
  color: white;
  font-family: 'Amatic SC', cursive;
  font-weight: bold;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

const Container = styled.div`
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Amatic SC', cursive;
  background-color: rgba(0, 0, 0, 0.7); /* Fundo semi-transparente para o conteúdo */
  border-radius: 10px;
  color: white;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Text = styled.p`
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const StyledTabs = styled(Tabs)`
  .react-tabs__tab-list {
    border-bottom: 5px solid #fff;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    list-style-type: none;
  }

  .react-tabs__tab {
    padding: 8px 20px;
    cursor: pointer;
    font-weight: bold;
    color: #FFF;
    border: none;
    border-bottom: 2px solid transparent;
    transition: color 0.2s, border-bottom-color 0.2s;
    font-family: 'Amatic SC', cursive;
    font-weight: bold;
    text-align: center;
  }

  .react-tabs__tab--selected {
    color: #F50;
    border-bottom: 2px solid #f50;
  }

  .react-tabs__tab--disabled {
    color: #aaa;
    cursor: not-allowed;
  }

  .react-tabs__tab:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    .react-tabs__tab-list {
      flex-direction: column;
    }

    .react-tabs__tab {
      padding: 10px 0;
    }
  }
`;

const Produtos = () => {
  return (
    <PageContainer>
      <Banner>
        <BannerTitle>Produtos</BannerTitle>
      </Banner>
      <Container>
        <StyledTabs>
          <TabList>
            <Tab>Programação e Desenvolvimento</Tab>
            <Tab>Inglês para Artistas</Tab>
            <Tab>Produção Audiovisual</Tab>
            <Tab>Produção Musical</Tab>
            <Tab>Edição</Tab>
          </TabList>

          <TabPanel>
            <SectionTitle>Programação e Desenvolvimento</SectionTitle>
            <Text>Trabalhamos com soluções atuais para os mais diversos desafios. Se você precisa de alguma aplicação web ou mobile, conte com os nossos serviços.</Text>
          </TabPanel>

          <TabPanel>
            <SectionTitle>Inglês para Artistas</SectionTitle>
            <Text>Se você precisa aprender ou melhorar seu inglês em tempo recorde, com uma metodologia criada para músicos e artistas em geral, essa é a sua chance. Acompanhamento personalizado e direcionado para sua atuação profissional.</Text>
          </TabPanel>

          <TabPanel>
            <SectionTitle>Produção Audiovisual</SectionTitle>
            <Text>Trabalhamos com captação de áudio e imagem para artistas e empresas. Fazemos desde a pré-produção até a entrega final do material que supra todas as necessidades dos nossos parceiros.</Text>
          </TabPanel>

          <TabPanel>
            <SectionTitle>Produção Musical</SectionTitle>
            <Text>Pensamos em todos os aspectos da carreira de um artista para ajudar a produzir suas obras e obter os melhores resultados para a realidade de cada artista.</Text>
          </TabPanel>

          <TabPanel>
            <SectionTitle>Edição</SectionTitle>
            <Text>Se você captou seu material por conta e precisa somente da edição desse material, temos a solução para o seu caso. Fazemos toda a parte de tratamento de cor, cortes, legendas e personalização dos seus materiais audiovisuais.</Text>
          </TabPanel>
        </StyledTabs>
      </Container>
    </PageContainer>
  );
};

export default Produtos;
