import React from 'react';
import styled from 'styled-components';
import background from '../assets/img/background.png';
import bannerImg from '../assets/img/agreg-background.jpg';

// Importe cada imagem de logo corretamente
import carlosFelixLogo from '../assets/img/artistas/carlos-felix.jpeg';
import wilneyDovhepolyLogo from '../assets/img/artistas/wilney-dovhepoly.jpeg';
import bandaGorrosLogo from '../assets/img/artistas/banda-gorros.jpg';
import bandaSuperSaiyajeansLogo from '../assets/img/artistas/banda-supersaiyajeans.jpg';
import bandaAppleTieLogo from '../assets/img/artistas/banda-appletie.jpg';
import bandaBOkayLogo from '../assets/img/artistas/banda-bokay.jpeg';

const PageContainer = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding-bottom: 10vh;
`;

const Banner = styled.section`
  background-image: url(${bannerImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 20vh;
`;

const BannerTitle = styled.h2`
  font-size: 5rem;
  color: white;
  font-family: 'Amatic SC', cursive;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 4rem;
  }

  @media (max-width: 480px) {
    font-size: 3rem;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 5rem;
  height: auto;

  @media (max-width: 768px) {
    padding: 2rem;
  }

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const Card = styled.a`
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px solid #333;
  border-radius: 15px;
  text-decoration: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: #333;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    height: 90px; /* Ajuste de altura para mobile */
  }

  @media (min-width: 769px) {
    height: 80px; /* Ajuste de altura para desktop */
  }
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 1rem;
  border-radius: 50%;

  @media (max-width: 768px) {
    margin-right: 0.5rem; /* Ajuste de margem para mobile */
  }
`;

const Name = styled.span`
  font-size: 2rem;
  font-family: 'Amatic SC', cursive;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const artists = [
  { name: 'Carlos Félix', logo: carlosFelixLogo, link: 'https://ocarlosfelix-site.vercel.app/' },
  { name: 'Wilney Dovhepoly', logo: wilneyDovhepolyLogo, link: 'https://site-do-wilney.com' },
  { name: 'Banda Gorros', logo: bandaGorrosLogo, link: 'https://www.bandagorros.com.br' },
  { name: 'Banda SuperSaiyajeans', logo: bandaSuperSaiyajeansLogo, link: 'https://site-da-banda-supersaiyajeans.com' },
  { name: 'Banda AppleTie', logo: bandaAppleTieLogo, link: 'https://www.appletie.com.br' },
  { name: 'Banda B.Okay', logo: bandaBOkayLogo, link: 'https://site-da-banda-bokay.com' },
];

const Artistas = () => {
  return (
    <PageContainer>
      <Banner>
        <BannerTitle>Artistas</BannerTitle>
      </Banner>
      <Container>
        {artists.map((artist, index) => (
          <Card key={index} href={artist.link} target="_blank" rel="noopener noreferrer">
            <Logo src={artist.logo} alt={artist.name} />
            <Name>{artist.name}</Name>
          </Card>
        ))}
      </Container>
    </PageContainer>
  );
};

export default Artistas;
