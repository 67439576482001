import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/img/bardologo.png';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #000;
  position: fixed;
  width: 100%;
`;

const Logo = styled.img`
  height: 50px;
`;

const Nav = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-right: 4vw;

    li {
      margin: 0 1rem;

      a {
        text-decoration: none;
        color: white;
        font-family: 'Amatic SC', cursive;
        font-size: 1.5rem;
      }

      a:hover {
        color: #ff6347;
      }
    }
  }
`;

const HeaderDesktop = () => {
  return (
    <HeaderContainer>
      <Link to="/">
        <Logo src={logo} alt="Logo O Bardo" />
      </Link>
      <Nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/quemsomos">Quem Somos</Link></li>
          <li><Link to="/produtos">Produtos</Link></li>
          <li><Link to="/artistas">Artistas</Link></li>
          <li><a href="https://instagram.com/obardoprodutora" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          <li><a href="https://anchor.fm/bardocast" target="_blank" rel="noopener noreferrer">Bardocast</a></li>
        </ul>
      </Nav>
    </HeaderContainer>
  );
};

export default HeaderDesktop;
